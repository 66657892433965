// @ts-check
import React, { useState } from 'react';

const Item = props => {
  const [item, setItem] = useState(props.item);
  const [isEditing, setIsEditing] = useState(false);
  const initialValue = props.item.domain;

  const handleInputChange = event => {
    const { name, value } = event.target;
    setItem({ ...item, [name]: value });
  };

  const handleCheckedChange = event => {
    const { name, value } = event.target;
    setItem({ ...item, [name]: value });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsEditing(false);
    props.updateItem(item.id, item, initialValue);
  };

  return (
    <>
      <tr>
        <td>
          <input type="checkbox" name={item.id} id={item.id} onChange={handleCheckedChange} />
        </td>
        <td>
          {isEditing ? (
            <>
              <form method="post" onSubmit={handleSubmit}>
                <input
                  className="form-input"
                  type="text"
                  name="domain"
                  value={item.domain}
                  onChange={handleInputChange}
                />
              </form>
            </>
          ) : (
            <>{item.domain}</>
          )}
        </td>
        <td style={{ textAlign: `right` }}>
          {isEditing ? (
            <>
              <button
                className="btn btn-primary btn-sm mr-2"
                title="editieren"
                onClick={handleSubmit}
              >
                <i className="icon icon-check" />
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-default btn-sm mr-2"
                title="editieren"
                onClick={() => setIsEditing(true)}
              >
                <i className="icon icon-edit" />
              </button>
              <button
                className="btn btn-error btn-sm"
                title="löschen"
                onClick={() => window.confirm('Sind Sie sicher?') && props.deleteItem(item.id)}
              >
                <i className="icon icon-delete" />
              </button>
            </>
          )}
        </td>
      </tr>
    </>
  );
};

export default Item;
